<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="access">
            <div class="access_top">
              <div class="logo_area">
                <img src="@/assets/static/images/logo.svg" alt="모두 함께해" />
              </div>
              <p class="ft_m_18">접근 권한 안내</p>
              <p class="ft_r_14">
                [모두 함께해] 서비스 이용을 위해<br />다음 권한들을 사용합니다.
              </p>
              <div class="line_grey"></div>
            </div>
            <div class="access_bottom">
              <div class="access_box m_30">
                <div class="img_box">
                  <img src="@/assets/static/images/i_camera.png" alt="camera" />
                </div>
                <div class="text_box">
                  <p class="ft_m_14">카메라</p>
                  <p class="ft_r_14">민원 작성 시 이미지 촬영</p>
                </div>
              </div>
              <div class="access_box">
                <div class="img_box">
                  <img
                    src="@/assets/static/images/i_gallery_orange.png"
                    alt="gallery"
                  />
                </div>
                <div class="text_box">
                  <p class="ft_m_14">사진</p>
                  <p class="ft_r_14">민원 작성 시 이미지 첨부</p>
                </div>
              </div>
              <div class="access_box">
                <div class="img_box">
                  <img
                    src="@/assets/static/images/i_download.png"
                    alt="download"
                  />
                </div>
                <div class="text_box">
                  <p class="ft_m_14">저장공간</p>
                  <p class="ft_r_14">민원 작성 시 이미지 첨부</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page_btn">
          <div class="btn_wrap">
            <button class="btn on" @click="fnPage">확인</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// import { getCurrentInstance } from 'vue'
// import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'auth-index',
  description: '접근권한 확인 페이지',
  setup() {
    // const { proxy } = getCurrentInstance()
    // const store = useStore()
    const router = useRouter()
    // const userData = store.getters['user/getData']

    const fnPage = () => {
      // if (proxy.$Util.isEmpty(userData)) {
      router.push({ path: '/sign/in' })
      // } else {
      //   router.push({ path: '/main', query: { isRoot: 1 } })
      // }
    }
    return { fnPage }
  }
}
</script>

<style scoped></style>
